var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landingPage"},[_c('section',{staticClass:"conOfAllHome"},[(_vm.featuredBunner)?_c('section',{staticClass:"section_headPage",style:({ 'background-image': 'url(' + _vm.featuredBunner.image + ')' })},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5","sm":"8"}},[_c('div',{staticClass:"conDescription"},[_c('h1',{staticClass:"mianTitleResposive"},[_vm._v(_vm._s(_vm.featuredBunner.title))]),_c('p',{staticClass:"my-5"},[_vm._v(" "+_vm._s(_vm.featuredBunner.description)+" ")]),(_vm.featuredBunner.has_url)?_c('v-btn',{staticClass:"primay-outline-btn",attrs:{"outlined":"","href":_vm.featuredBunner.url,"target":"_blank","ripple":false}},[_vm._v(" REGISTER NOW ")]):_vm._e()],1)])],1)],1)],1):_c('div',{staticClass:"loaderContainer fixedEv"},[_c('v-progress-circular',{attrs:{"size":120,"width":4,"indeterminate":"","color":"primary"}})],1),(_vm.banners)?_c('section',{staticClass:"sectionEvents"},[_c('v-row',[_c('v-col',{staticClass:"offset-md-1 pa-0",attrs:{"cols":"12","md":"10"}},[_c('carousel',{attrs:{"autoplay":true,"margin":20,"responsive":{
              0: {
                items: 1,
                nav: false,
                loop: false,
                dots: true,
              },
              768: {
                items: 2,
                nav: false,
                loop: false,
                dots: true,
              },
              1000: {
                items: 3,
                nav: false,
                loop: false,
                dots: false,
              },
            }}},[_c('template',{slot:"next"},[_c('span',{staticClass:"next rightEelemt arrowsElements"},[_c('v-icon',{staticClass:"iconStyle"},[_vm._v(" mdi-arrow-right ")])],1)]),_vm._l((_vm.banners),function(banner,index){return _c('div',{key:index,staticClass:"conItems"},[_c('router-link',{staticClass:"linkEvents",attrs:{"to":{
                  name: 'eventItem',
                  params: {
                    id: banner.id,
                    eventType: 'upcoming',
                  },
                }}},[_c('h2',[_vm._v(" "+_vm._s(banner.title)+" ")]),_c('p',[_vm._v(" "+_vm._s(banner.description)+" ")])])],1)}),_c('template',{slot:"prev"},[_c('span',{staticClass:"prev leftEelemt arrowsElements"},[_c('v-icon',{staticClass:"iconStyle"},[_vm._v(" mdi-arrow-left ")])],1)])],2)],1)],1)],1):_vm._e(),_c('div',{staticClass:"conOfBgEventSection"},[_c('div',{staticClass:"imgbgAbs"}),_c('section',{staticClass:"sectionEventsCalender"},[_c('EventCalender')],1),_c('section',{staticClass:"sectionMediaCalender"},[_c('MediaCenter')],1)]),_c('section',{staticClass:"stayWithUs"},[_c('v-container',[_c('v-row',{staticClass:"align-center"},[_c('v-col',{attrs:{"md":"5","cols":"12"}},[_c('div',{staticClass:"fHeader mianTitleResposive"},[_vm._v(" Stay updated with "),_c('span',[_vm._v("SSBD")])]),_c('div',{staticClass:"desc"},[_vm._v(" Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt ")])]),_c('v-col',{staticClass:"offset-md-2",attrs:{"md":"5","cols":"12"}},[_c('div',[_c('div',{staticClass:"conSubscribe d-flex"},[_c('v-text-field',{staticClass:"subScribeInput",attrs:{"solo":"","hide-details":"","elevation-0":"","placeholder":"Enter Email Address","aria-label":"Search"}}),_c('v-btn',{staticClass:"subscibeBtn"},[_vm._v(" subscribe ")])],1)])])],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }