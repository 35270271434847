<template>
  <div class="landingPage">
    <section class="conOfAllHome">
      <section
        v-if="featuredBunner"
        class="section_headPage"
        :style="{ 'background-image': 'url(' + featuredBunner.image + ')' }"
      >
        <v-container>
          <v-row>
            <v-col cols="12" md="5" sm="8">
              <div class="conDescription">
                <h1 class="mianTitleResposive">{{ featuredBunner.title }}</h1>
                <p class="my-5">
                  {{ featuredBunner.description }}
                </p>
                <v-btn
                  class="primay-outline-btn"
                  outlined
                  v-if="featuredBunner.has_url"
                  :href="featuredBunner.url"
                  target="_blank"
                  :ripple="false"
                >
                  REGISTER NOW
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <div class="loaderContainer fixedEv" v-else>
        <v-progress-circular
          :size="120"
          :width="4"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <section class="sectionEvents" v-if="banners">
        <v-row>
          <v-col cols="12" md="10" class="offset-md-1 pa-0">
            <carousel
              :autoplay="true"
              :margin="20"
              :responsive="{
                0: {
                  items: 1,
                  nav: false,
                  loop: false,
                  dots: true,
                },
                768: {
                  items: 2,
                  nav: false,
                  loop: false,
                  dots: true,
                },
                1000: {
                  items: 3,
                  nav: false,
                  loop: false,
                  dots: false,
                },
              }"
            >
              <template slot="next">
                <span class="next rightEelemt arrowsElements">
                  <v-icon class="iconStyle"> mdi-arrow-right </v-icon>
                </span>
              </template>

              <div
                class="conItems"
                v-for="(banner, index) in banners"
                :key="index"
              >
                <router-link
                  class="linkEvents"
                  :to="{
                    name: 'eventItem',
                    params: {
                      id: banner.id,
                      eventType: 'upcoming',
                    },
                  }"
                >
                  <h2>
                    {{ banner.title }}
                  </h2>
                  <p>
                    {{ banner.description }}
                  </p>
                </router-link>

                <!-- <v-btn class="moreDetails" text>
                 More details
              </v-btn> -->
              </div>

              <template slot="prev">
                <span class="prev leftEelemt arrowsElements">
                  <v-icon class="iconStyle"> mdi-arrow-left </v-icon>
                </span>
              </template>
            </carousel>
          </v-col>
        </v-row>
      </section>
      <div class="conOfBgEventSection">
        <div class="imgbgAbs"></div>
        <section class="sectionEventsCalender">
          <EventCalender />
        </section>
        <section class="sectionMediaCalender">
          <MediaCenter />
        </section>
      </div>

      <section class="stayWithUs">
        <v-container>
          <v-row class="align-center">
            <v-col md="5" cols="12">
              <div class="fHeader mianTitleResposive">
                Stay updated with <span>SSBD</span>
              </div>
              <div class="desc">
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt
              </div>
            </v-col>
            <v-col md="5" cols="12" class="offset-md-2">
              <div>
                <div class="conSubscribe d-flex">
                  <v-text-field
                    class="subScribeInput"
                    solo
                    hide-details
                    elevation-0
                    placeholder="Enter Email Address"
                    aria-label="Search"
                  />
                  <v-btn class="subscibeBtn"> subscribe </v-btn>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </section>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import EventCalender from "./components/events-calender";
import MediaCenter from "./components/media-center";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();

// import LoaderFeatureBanner from "@/modules/shared/components/loader";

export default {
  data: () => ({
    isLoading: false,
    model: null,
    featuredBunner: null,
    banners: null,
    formContact: {
      name: "",
      email: "test@",
      comment: "hi admin",
    },
    latestEvents: [
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
      {
        event_id: 1,
        title: "LOREM IPSUM",
        descreption:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore",
      },
    ],
  }),
  components: {
    carousel,
    EventCalender,
    MediaCenter,
    // LoaderFeatureBanner,
  },
  methods: {
    makeSubscribe() {
      // console.log("ssssssssssssssss");
    },
    getFeatureBanner() {
      apiServices.get("featured-banner").then((res) => {
        if (res.data) {
          // console.log("res", res);
          this.featuredBunner = res.data[0];
        }
      });
    },
    getBanners() {
      apiServices.get("banners").then((res) => {
        if (res.data) {
          this.banners = res.data;
        }
      });
    },
  },
  created() {
    this.getFeatureBanner();
    this.getBanners();
  },
};
</script>

<style lang="scss" scoped>
@import "./_landing-page.scss";
</style>
