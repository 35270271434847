var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.mediaCenter)?_c('div',{staticClass:"mediaCenterContainer my-3"},[_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"conVideo text-center"},[_c('youtube',{ref:"youtube",attrs:{"video-id":_vm.videoId}})],1)])],1),_c('v-row',[_c('v-col',{staticClass:"offset-md-1 pa-0",attrs:{"cols":"12","md":"10"}},[_c('carousel',{staticClass:"carasualStyle",attrs:{"autoplay":false,"margin":20,"responsive":{
            0: {
              items: 3,
              nav: false,
              loop: false,
              dots: true,
            },
            768: {
              items: 5,
              nav: false,
              loop: false,
              dots: true,
            },
            1000: {
              items: 7,
              nav: false,
              loop: false,
              dots: false,
            },
          }}},_vm._l((_vm.mediaCenter),function(media,index){return _c('div',{key:index,staticClass:"conItems"},[_c('div',{staticClass:"linkEvents",class:{ active: media.active },on:{"click":function($event){return _vm.changeMedia(index)}}},[_c('img',{attrs:{"src":'https://img.youtube.com/vi/' + media.url + '/0.jpg',"alt":"item of the Image"}})])])}),0)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }